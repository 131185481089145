import {
    parsePredefinedInterval as parse,
    formatDateInterval as formatInterval
} from './predefinedDateIntervals.js';

import {
    getUrlParametersFromReportSpecification as editorialGetUrlParametersFromReportSpecification,
    urlParametersToReportSpecification as editorialUrlParametersToReportSpecification,
    reportSpecificationToUrl as editorialReportSpecificationToUrl
} from './urlParamsToReportSpecification/editorial/index.js';

import { urlParamBindings as editorialUrlParamBindings } from './urlParamsToReportSpecification/editorial/urlParamBindings.js';

import {
    getUrlParametersFromReportSpecification as marketingGetUrlParametersFromReportSpecification,
    urlParametersToReportSpecification as marketingUrlParametersToReportSpecification,
    reportSpecificationToUrl as marketingReportSpecificationToUrl
} from './urlParamsToReportSpecification/marketing/index.js';

import { groupFieldSelector as editorialGroupFieldSelector } from './columns/maps/editorial/groupFieldSelector.js';
import { groupFieldSelector as marketingGroupFieldSelector } from './columns/maps/marketing/groupFieldSelector.js';

import {
    getPossibleSortColumns as editorialGetPossibleSortColumns,
    EMPTY_GROUP_BY_KEY as editorialEmptyGroupByKey,
    DefaultReportColumns as editorialDefaultReportColumns,
    DefaultReportColumnsMap as editorialDefaultReportColumnsMap,
    isTableTypeReport as editorialIsTableTypeReport
} from './columns/maps/editorial/index.js';

import {
    getPossibleSortColumns as marketingGetPossibleSortColumns,
    EMPTY_GROUP_BY_KEY as marketingEmptyGroupByKey,
    DefaultReportColumns as marketingDefaultReportColumns,
    DefaultReportColumnsMap as marketingDefaultReportColumnsMap,
    isTableTypeReport as marketingIsTableTypeReport
} from './columns/maps/marketing/index.js';

import { urlParamBindings as marketingUrlParamBindings } from './urlParamsToReportSpecification/marketing/urlParamBindings.js';

export { AGGREGATE_WIDGET_VARIATIONS } from './columns/maps/common/aggregateWidgetVariations.js';
export { getColumnFullName } from './columns/maps/common/getColumnFullName.js';
export { isColumnCalculable } from './columns/maps/common/isColumnCalculable.js';
export { ColumnFormatNames } from './columns/maps/common/columnFormatNames.js';

export const urlParamsInfo = {
    editorial: {
        getUrlParametersFromReportSpecification:
            editorialGetUrlParametersFromReportSpecification,
        urlParametersToReportSpecification:
            editorialUrlParametersToReportSpecification,
        reportSpecificationToUrl: editorialReportSpecificationToUrl,
        urlParamBindings: editorialUrlParamBindings
    },
    marketing: {
        getUrlParametersFromReportSpecification:
            marketingGetUrlParametersFromReportSpecification,
        urlParametersToReportSpecification:
            marketingUrlParametersToReportSpecification,
        reportSpecificationToUrl: marketingReportSpecificationToUrl,
        urlParamBindings: marketingUrlParamBindings
    }
};

export {
    getIntervalAndDecrement,
    predefinedDateIntervals,
    stringifyInterval
} from './predefinedDateIntervals.js';

export {
    reportTypeNames,
    reportTypes,
    granularities,
    granularityNames,
    scheduleTypes,
    scheduleTypeNames,
    scheduleTypeNamesAdj,
    orderDirections,
    orderDirectionNames,
    showByNames,
    showByTypes,
    groupByNames,
    groupByTypes,
    allGroupByTypes,
    orderByValues,
    // new schemas
    DateIntervalSchema,
    DateIntervalWithPredefinedValuesSchema,
    DateIntervalWithPredefinedValuesWithSelectedTimeframeSchema,
    GeographyFilterSchema,
    EditorialReportSpecificationSchema,
    MarketingReportSpecificationSchema,
    EditorialReportShowBySchema,
    ReportGranularitySchema,
    EditorialReportTypeSchema,
    MarketingReportTypeSchema,
    SaveReportRequestSchema,
    UpdateReportRequestSchema,
    SavedReportSchema,
    SaveIndividualReportRequestSchema,
    UpdateIndividualReportRequestSchema,
    IndividualReportSchema,
    GetIndividualReportsForPublicationRequestSchema,
    GetIndividualReportsRecipientsRequestSchema,
    DeleteIndividualReportRequestSchema,
    GetIndividualReportsStatesForAuthorSchema,
    UpdateIndividualReportStateForAuthorSchema,
    SearchTagsRequestSchema,
    SearchArticlesRequestSchema,
    SearchAttributesRequestSchema,
    GetHiddenArticlesRequestSchema,
    HideArticleRequestSchema,
    UnhideArticleRequestSchema,
    SearchLogsRequestSchema,
    SearchImportsRequestSchema,
    ImportRequestSchema,
    SearchAuthorRequestSchema,
    AuthorsStatsRequestSchema,
    AuthorSyncWithSitecoreRequestSchema,
    UpdateAuthorRequestSchema,
    FindAuthorByIdRequestSchema,
    GetPublicationRequestSchema,
    UpdatePublicationRequestSchema,
    GetEditorialAnalyticsSettingsRequestSchema,
    SettingUpdateRequestSchema,
    GetCountriesRequestSchema,
    SearchCountriesRequestSchema,
    PageQuerySchema,
    ExportEditorialReportSchema,
    ExportMarketingReportSchema,
    ColumnGroupSchema,
    GetColumnGroupsForPublicationRequestSchema,
    GetColumnGroupsByIdsRequestSchema,
    DeleteColumnGroupRequestSchema,
    ColumnDefinitionSchema,
    CreateColumnGroupRequestSchema,
    CreateColumnGroupResponseSchema,
    UpdateColumnGroupRequestSchema,
    UpdateColumnGroupResponseSchema,
    buildCustomColumnGroupId,
    parseCustomColumnGroupId,
    isCustomColumnGroup,
    AggregateWidgetSchema,
    ColumnFormatSchema,
    OperationSchema,
    AnalyticsReportTypeSchema,
    AttributeFilterSchema
} from './schemas.js';

export const reportColumnsInfo = {
    editorial: {
        groupFieldSelector: editorialGroupFieldSelector,
        getPossibleSortColumns: editorialGetPossibleSortColumns,
        EMPTY_GROUP_BY_KEY: editorialEmptyGroupByKey,
        DefaultReportColumns: editorialDefaultReportColumns,
        DefaultReportColumnsMap: editorialDefaultReportColumnsMap,
        isTableTypeReport: editorialIsTableTypeReport
    },
    marketing: {
        groupFieldSelector: marketingGroupFieldSelector,
        getPossibleSortColumns: marketingGetPossibleSortColumns,
        EMPTY_GROUP_BY_KEY: marketingEmptyGroupByKey,
        DefaultReportColumns: marketingDefaultReportColumns,
        DefaultReportColumnsMap: marketingDefaultReportColumnsMap,
        isTableTypeReport: marketingIsTableTypeReport
    }
};

export { GroupByOperationNames } from './groupByOperationNames.js';

/**
 *
 * @type {(value: import('smg-iq').DateIntervalWithPredefinedValues, additional: typeof intervals) => import('smg-iq').DateInterval}
 */
export const parsePredefinedInterval = parse;

/**
 * @type {(
 *      interval: import('smg-iq').DateIntervalWithPredefinedValuesWithSelectedTimeframe,
 *      additionalIntervals: Array<{
 *          key: string;
 *          label: string;
 *          range: import('smg-iq').DateIntervalWithPredefinedValuesWithSelectedTimeframe;
 *      }>,
 *      hideDatesForKnownRanges?: boolean
 * ) => string}
 */
export const formatDateInterval = formatInterval;

export { standardColumnGroups } from './standardColumnGroups.js';
