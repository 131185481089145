export default {
   "Settings": {
      "IDs": {
         "ArticleViewsImportEnabled": "ARTICLE_VIEWS_IMPORT_ENABLED",
         "DefaultEditingLaborCostPerHour": "DEFAULT_EDITING_LABOR_COST_PER_HOUR"
      }
   },
   "AuthorTypesMapping": {
      "Contributor": "Contributor",
      "Freelance": "Freelance",
      "Staff": "Staff",
      "Sponsor": "Sponsor",
      "Influencer": "Influencer"
   },
   "ArticleTypesMapping": {
      "editorial": "Editorial",
      "feature": "Featured",
      "sponsoredarticle": "Sponsored Article",
      "newsanalysis": "News Analysis",
      "news": "News",
      "interview": "Interview",
      "discussionpoint": "Discussion Point",
      "bestpractices": "Best Practices",
      "productreview": "Product Review",
      "podcast": "Podcast",
      "pressrelease": "Press Release"
   },
   "ArticleTypesDefaultValues": {
      "editorial": true,
      "feature": true,
      "sponsoredarticle": false,
      "newsanalysis": true,
      "news": true,
      "interview": true,
      "discussionpoint": true,
      "bestpractices": true,
      "productreview": true,
      "podcast": true,
      "pressrelease": true
   },
   "SavedReportUTCStartHour": 9,
   "SavedReportUTCStartMinutes": 0
};