import { lazy } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { LoginComponent as LoginPage } from 'src/components/shared/Login';

import { DashboardLayout } from 'src/components/shared/DashboardLayout';

const NotFound = lazy(async () => ({
    default: await (await import('src/pages/NotFound')).NotFound
}));

const UserList = lazy(async () => ({
    default: await (await import('src/pages/settings/UserList')).UserList
}));

const SystemSettings = lazy(async () => ({
    default: await (
        await import('src/pages/settings/SystemSettings')
    ).SystemSettings
}));

const AuthorsList = lazy(async () => ({
    default: await (await import('src/pages/analytics/AuthorsList')).AuthorsList
}));

const Author = lazy(async () => ({
    default: await (await import('src/pages/analytics/Author.js')).Author
}));

const EditorialAnalyticsReports = lazy(async () => ({
    default: await (
        await import('src/pages/analytics/Reports/editorial/index.js')
    ).EditorialAnalyticsReports
}));

const MarketingAnalyticsReports = lazy(async () => ({
    default: await (
        await import('src/pages/analytics/Reports/marketing/index.js')
    ).MarketingAnalyticsReports
}));

// const FineCharts = lazy(async () => ({
//     default: await (
//         await import('src/pages/analytics/FineCharts')
//     ).FineCharts
// }));

const SavedReports = lazy(async () => ({
    default: await (
        await import('src/pages/analytics/SavedReports')
    ).SavedReports
}));

const MonthsList = lazy(async () => ({
    default: await (await import('src/pages/analytics/MonthsList')).MonthsList
}));

const PublicationSettings = lazy(async () => ({
    default: await (
        await import(
            'src/pages/analytics/PublicationSettings/PublicationSettings'
        )
    ).PublicationSettings
}));

const EditorialAnalyticsLogs = lazy(async () => ({
    default: await (
        await import('src/pages/analytics/Logs')
    ).EditorialAnalyticsLogs
}));

const EditorialAnalyticsGAImports = lazy(async () => ({
    default: await (await import('src/pages/analytics/GAImports')).GAImports
}));

const routes = [
    {
        path: 'app',
        element: <DashboardLayout />,
        children: [
            // { path: 'banners', element: <Banners /> },
            {
                path: 'analytics',
                element: <Outlet />,
                children: [
                    {
                        path: 'reports/editorial',
                        element: <EditorialAnalyticsReports />
                    },
                    {
                        path: 'reports/marketing',
                        element: <MarketingAnalyticsReports />
                    },
                    {
                        path: 'months',
                        element: <MonthsList />
                    },
                    {
                        path: 'months/:year/:month',
                        element: <MonthsList />
                    },
                    {
                        path: 'authors-list',
                        element: <AuthorsList />
                    },
                    {
                        path: 'reports/saved-reports',
                        element: <SavedReports />
                    },
                    {
                        path: 'reports/saved-reports/:reportId',
                        element: <SavedReports />
                    },
                    {
                        path: 'author-profile/:id',
                        element: <Author />
                    },
                    {
                        path: 'publication-settings',
                        element: <PublicationSettings />
                    },
                    {
                        path: 'logs',
                        element: <EditorialAnalyticsLogs />
                    },
                    {
                        path: 'ga-imports',
                        element: <EditorialAnalyticsGAImports />
                    }
                ]
            },
            {
                path: 'editorial-analytics',
                element: <Outlet />,
                children: [
                    {
                        path: 'reports/authors',
                        element: <EditorialAnalyticsReports />
                    }
                ]
            },
            { path: '*', element: <Navigate to="/404" /> }
        ]
    },
    {
        path: 'admin',
        element: <DashboardLayout />,
        children: [
            { path: 'users', element: <UserList /> },
            { path: 'system-settings', element: <SystemSettings /> },
            { path: '*', element: <Navigate to="/404" /> }
        ]
    },
    {
        path: '/login',
        element: <LoginPage />
    },
    {
        path: '/',
        element: <DashboardLayout />,
        children: [
            { path: '404', element: <NotFound /> },
            {
                path: '/',
                element: <Navigate to="/app/analytics/months" />
            },
            { path: '*', element: <Navigate to="/404" /> }
        ]
    }
];

export { routes };
