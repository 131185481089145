const DEFAULT_TIMEOUT = 180 * 1000;

export default class CacheMap extends Map {
    #expirationTimeout = DEFAULT_TIMEOUT;

    #timers = new Map();

    constructor(expirationTimeout = DEFAULT_TIMEOUT, ...rest) {
        super(...rest);
        this.#expirationTimeout = expirationTimeout;
        if (Array.isArray(rest)) {
            const keysToInvalidate = [];
            for (let i = 0; i < rest.length; i++) {
                if (Array.isArray(rest[i])) {
                    keysToInvalidate.push(rest[i][0]);
                }
            }
            const timer = setTimeout(() => {
                this.#timers.delete(timer);
                keysToInvalidate.forEach((k) => this.delete(k));
            }, this.#expirationTimeout);
            this.#timers.set(timer, true);
        }
    }

    dispose() {
        for (const [key] of this.#timers) {
            clearTimeout(key);
            this.#timers.delete(key);
        }
    }

    set(key, value) {
        super.set(key, value);
        const timer = setTimeout(() => {
            this.delete(key);
        }, this.#expirationTimeout);
        this.#timers.set(timer, true);
    }
}
